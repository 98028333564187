import React from "react";
import styled from "styled-components";
import { Font, Color, rem, media, responsive } from "../../utils/style";
import { parseMarkdown } from "../../utils/markdown";
import sanitizeHtml from "isomorphic-html-sanitize";

const AccordionWrapper = styled.div.attrs(p => ({
  id: `accordion-item-${p.index}`,
}))`
  border-top: 1px solid rgba(0, 0, 0, 0.16);

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  }
`;

const AccordionHeader = styled.button.attrs(p => ({
  id: `accordion-item-${p.index}_header`,
  className: "product-questions__accordion__header",
}))`
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  padding: 0;
`;

const HeaderText = styled.h3.attrs(p => ({
  id: `accordion-item-${p.index}_header_title`,
}))`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${p => (p.small ? rem(16) : rem(18))};
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(28)};
  padding: ${p =>
    p.small
      ? p.open
        ? "16px 0 8px"
        : "16px 0"
      : p.open
      ? "24px 0 16px"
      : "24px 0"};
  margin: 0;
  max-width: calc(100% - 18px - 8px);

  ${media.mobile`
    font-size: ${rem(16)};
  `}

  ${responsive.md`
    max-width: calc(100% - 18px - 16px);
  `};

  &:hover {
    cursor: pointer;
  }
`;

const AccordionBody = styled.div.attrs(p => ({
  id: `accordion-item-${p.index}_body`,
}))`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  padding-bottom: ${rem(24)};
  display: none;

  ${Font.dutch} &.open {
    display: block;
  }

  a,
  a[data-modal="supplement-facts"] {
    display: inline-block;
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.56;
    }
  }
`;

const Toggle = styled.div.attrs(p => ({
  id: `accordion-item-${p.index}_header_toggle`,
}))`
  position: absolute;
  right: 0;
  top: calc(50% - 5px);
  width: 18px;
  height: 12px;
  transform-origin: center;
  cursor: pointer;

  &.open {
    transform: rotateZ(180deg);
  }

  &:before {
    position: absolute;
    left: 0;
    content: "";
    height: 2px;
    width: 12px;
    background-color: ${Color.ritualBlue};
    transform: translateY(5px) rotate(45deg);
    transform-origin: center;
  }

  &:after {
    position: absolute;
    left: 0;
    content: "";
    height: 2px;
    width: 12px;
    background-color: ${Color.ritualBlue};
    transform: translateX(7.5px) translateY(5px) rotate(-45deg);
    transform-origin: center;
  }
`;

export default class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: null,
    };
  }

  handleHeaderClick() {
    this.props.headerClick();
  }

  render() {
    const { item, open, small, index } = this.props;

    const sanitizedContent = sanitizeHtml(item.body, { allowedTags: [] });
    
    let markdownConvertedContent = parseMarkdown(sanitizedContent);

    return (
      <AccordionWrapper index={index}>
        <AccordionHeader
          index={index}
          onClick={this.handleHeaderClick.bind(this)}
          aria-expanded={open}
          aria-controls={`accordion-item-${index}_body`}
        >
          <Toggle index={index} className={open ? "open" : ""} />
          <HeaderText index={index} small={small} open={open}>
            {item.header}
          </HeaderText>
        </AccordionHeader>
        <AccordionBody
          index={index}
          role="region"
          aria-labelledby={`accordion-item-${index}_header`}
          className={open ? "open" : ""}
          dangerouslySetInnerHTML={{ __html: markdownConvertedContent }}
        />
      </AccordionWrapper>
    );
  }
}
