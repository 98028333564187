import React from "react";
import styled from "styled-components";
import AccordionItem from "./AccordionItem";

// Utils
import metrics from "../../utils/metrics";

const AccordionWrapper = styled.div.attrs(
  p =>
    !p.noGridAligment && {
      className: "col-12 col-md-8 offset-md-2",
    },
)`
  z-index: 10;
`;

export default class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: null,
    };
  }

  openTab(index, eventDetails) {
    const oldIndex = this.state.openIndex;
    const newIndex = oldIndex === index ? null : index;

    this.setState({ openIndex: newIndex });
    if (this.props.subscribe) {
      this.props.subscribe(newIndex);
    }

    // If the index hasn't updated, do not report an event.
    if (newIndex !== null) {
      this.reportOpenEvent(newIndex, eventDetails);
    }

    // Only report the close event if the eventDetails prop exists. This prop
    // is an indication that we should override the default "Dr. Luke QA
    // Expanded" event.
    if (oldIndex !== null && eventDetails) {
      this.reportCloseEvent(oldIndex, eventDetails);
    }
  }

  reportOpenEvent(index, eventDetails) {
    let event = {
      title: this.props.items[index].header,
      nonInteraction: false,
    };
    let name = "Dr. Luke QA Expanded";
    // If additional event details have been passed, add those details to
    // the default event and use the generic (non Dr. Luke) name.
    if (eventDetails) {
      name = "Accordion Open";
      event = { ...event, ...eventDetails };
    }
    metrics.track(name, event);
  }

  reportCloseEvent(index, eventDetails) {
    let event = {
      title: this.props.items[index].header,
      nonInteraction: false,
    };
    // If additional event details have been passed, add those details to
    // the default event.
    if (eventDetails) {
      event = { ...event, ...eventDetails };
    }
    metrics.track("Accordion Close", event);
  }

  render() {
    let { items, noGridAligment, small, eventDetails } = this.props;
    let { openIndex } = this.state;

    return (
      <AccordionWrapper id="accordion" noGridAligment={noGridAligment}>
        {items.map((item, i) => {
          return (
            <AccordionItem
              index={i}
              key={"item-" + i}
              headerClick={this.openTab.bind(this, i, eventDetails)}
              item={item}
              open={openIndex === i}
              small={small}
            />
          );
        })}
      </AccordionWrapper>
    );
  }
}
